@import '$Styles/config.less';

.navPanel {
    height:calc(100vh - 129px)
}
.thin-header .navPanel{
    height:calc(100vh - 94px);
}

.navPanel {
    display:block;
    background-color:#33303b;
    font-family: "mayonezbold", sans-serif;
    font-size: 1.2rem;
    padding: 1rem;
    color: #fefefe;
}

/*
.nav-item-base(){
    //font-family: "mayonezbold", sans-serif;
    //font-size: 20px;
    display: block;
    width: 100%;
    height: auto;
    padding: 5px 0 5px 20px;
    color: #fefefe;
    cursor:pointer;
}
*/

.geoSubItems {
    padding: 0.5rem;
    display: grid;
    grid-template: "a a" 1.5em;
    font-size: 0.8em;
}

.geoItemTitle {
    display:flex;
    justify-content: space-between;

}
.geoItems {
    display: block;
}

.chapterItems {
    display: grid;
    grid-template: "a a" 1.5em;
}

/*
.nav-panel{

    &__item{
        .nav-item-base;
        & a {
            color: #fefefe;
        }
    }
    &__2items {
        width: 40%;
        float:left;
        font-family: "Ubuntu condensed", serif;
        height: 30px;
    }
    .nav-panel__2items.nav-panel__geo-subitem {
        padding:0;
        width: 50%;
    }

    &__geo-subitems{
    }
    &__geo-item {
        clear:both;
    }
    //&__geo-item-title{
    //    background: url("@{image-path}/arrowDown.png") no-repeat right;
    //    background-position: 100% 0.5em;
    //    padding-right: 20px;
    //}
    &__searchbox{
        @media only screen and (max-width:768px){
            display:block;
        }
    }
    &__searchbox .searchbox-form {
        position: relative;
        background-color: transparent;
        & input {
            border:0;
            outline:none;
        }
        &__text {
            .nav-item-base;
            border: 0;
            background-color: transparent;
            width:75%;
            color:white;
            padding-left:30px;
        }
        &__text::-webkit-input-placeholder,
        &__text::-moz-placeholder,
        &__text:-ms-input-placeholder,
        &__text:-moz-placeholder,
        &__text::placeholder{
            color:white;
        }
    }
}*/
