.dateSeparator {
  @apply bg-gray-100 text-black sticky top-0 justify-center content-center;
  /*
  background-color: #f1f1f1;
  justify-content: center;
  align-content: center;
  position: sticky;
  top: 0;
  */
}
:global(.dark) .dateSeparator {
  @apply bg-gray-500 text-white;
}

.loadMore {
  font-family: "Ubuntu Condensed", serif;
  font-size: 1.7em;
  display: block;
  padding: 20px 10px;
  text-align: center;
  text-transform: uppercase;
  color: #8e8e8e;
  background-color: #ffffff;

  &:hover {
    color: #ffffff;
    background-color: #804b77;
  }
}


.desktopStream {
  background-color: white;
  height: calc(100vh - 85px);

  :global(.dark) & {
    @apply bg-gray-700;
  }

  :global(.thin-header) & {
    height: calc(100vh - 50px);
  }

  & .items > li {
    @apply font-gkText font-normal border-b border-b-gray-300
      py-2 px-4 box-border w-full flex flex-col text-black;

    :global(.dark) & {
      @apply border-b-gray-500 text-white;
    }
    /*
    font-family: "proxima-nova", sans-serif;
    font-weight: 700;
    font-style: normal;
    border-bottom: 1px solid #f1f1f1;
    padding: 5px 10px 5px 10px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    */

    &.advertising {
      padding: 0;
    }

    &.desktop {

    }
  }

  & .streamLink {
    @apply text-black;
    :global(.dark) & {
      @apply text-white;
    }
  }

  & .streamContent {
    display: flex;
    line-height: normal;
    flex-direction: row;
    min-height: 64px;
  }

  & .streamTitle {
    @apply overflow-hidden font-normal leading-3 text-sm text-gray-900 ml-2;
    :global(.dark) & {
      @apply text-white;
    }
    //font-size: 14px;
    //overflow: hidden;
    //margin: 0 0 5px 0;
    //font-weight: 400;
  }

  & .streamImage {
    margin: 4px 7px 0 0;
    padding: 0;

    & img {
      max-width: initial;
      border-radius: 0.5rem;
    }
  }

  & .streamChapter,
  & .streamRegion {
    color: #808080;
    padding: 0 2px;
  }

  & .streamDate {
    position: relative;
    top: 1px;
    margin-right: 5px;
  }

  & .streamIcon {
    font-size: 14px;
    position: relative;
    top: 2px;
    margin-right: 6px;
  }

  & .streamInfo,
  & .streamViews,
  & .streamStatus {
    font-family: "Ubuntu Condensed", serif;
    font-size: 10px;
    font-weight: normal;
  }

  & .streamInfo,
  & .streamViews {
    height: 17px;
    padding: 0;
    margin-left: 0;
    text-transform: uppercase;
    color: #a0a0a0;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
  }

  & .streamInfo {
    display: grid;
    grid-template: "a a a a a";
    width: 100%;
    //& li {
    //  width: 20%;
    //}
  }

  & .streamStatus > span {
    display: initial;
    color: #fff;
    padding: 3px 5px;
    margin: 0 5px;
    border-radius: 5px;
  }

}

@media only screen and (min-width: 1001px) {
  .mobileStream {
    display: none;
  }
}

@media only screen and (max-width: 1000px) {
  .mobileStream {
    display: block;
  }
}

.mobileStream {
  margin: auto;
  padding: 0;
  font-size: 14px;
  width: 100%;

  & .items {
    //padding: 20px;
    font-size: 1.25em;
    line-height: 1.25em;
    background-color: white;

    & > li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: nowrap;
      padding-bottom: 0.75em;
      color: #5c5c5c;

      &.top {
        font-weight: 700;
      }

      &.mobile {
        padding: 20px;
      }

      &.dateSeparator {
        top: 135px;
        padding: 0.75em;
      }


      :global(.thin-header) &.dateSeparator {
        top: 100px;
        z-index: 1;
      }

    }

  }

  & .streamDate {
    width: 6rem;
    font-family: "Ubuntu condensed", serif;
    font-size: 1.5rem;
  }

  & .streamStatus {
    font-family: "Ubuntu Condensed", serif;
    font-size: 12px;
    font-weight: normal;
    display: initial;
    color: #ffffff;
    padding: 1px 5px;
    margin-right: 5px;
  }

  & .streamTitle {
    width: 100%;
  }

}


@from-color: rgb(240, 240, 240);
@to-color: rgb(250, 250, 250);
@duration: 1s;

.animation-mixin() {
  0% {
    background-color: @from-color;
  }
  50% {
    background-color: @to-color;
  }
  100% {
    background-color: @from-color;
  }
}

@-webkit-keyframes blank {
  .animation-mixin;
}

@-moz-keyframes blank {
  .animation-mixin;
}

@-ms-keyframes blank {
  .animation-mixin;
}

@-o-keyframes blank {
  .animation-mixin;
}

@keyframes blank {
  .animation-mixin;
}

.blank-animation-mixin {
  border-radius: 10px;

  background-color: @from-color;
  animation-name: blank;
  animation-duration: @duration;
  animation-iteration-count: infinite;
}

.blankImage {
  display: block;
  float: left;
  width: 56px;
  height: 56px;
  margin: 4px 7px 0 0;
  padding: 0;

  .blank-animation-mixin;
}

.blankTitle {
  display: block;
  height: 3em;
  padding: 0;
  margin: 0 0.5em;
  .blank-animation-mixin;
}

.blankStreamInfo {
  display: inline-block;
  width: 100%;
  height: 1em;
  padding: 0;
  margin: 5px;
  .blank-animation-mixin;
}

.blankDate {
  display: inline-block;
  float: left;
  width: 3em;
  height: 1em;
  border-radius: 10px;

  .blank-animation-mixin;
}


.mobileStream {
  & .blankTitle {
    width: 100%;
  }
}

.desktopStream {
  & .blankTitle {
    width: 230px;
  }
}


.filter {
  @apply font-gkStatus overflow-hidden flex content-around z-10 bg-gray-200;
  :global(.dark) & {
    @apply bg-gray-800;
  }

  //font-family: "Ubuntu Condensed", serif;
  //overflow: hidden;
  //background-color: #e5edeb;
  //display: flex;
  //justify-content: space-around;
  //z-index: 1;

  .mobileStream & {
    position: sticky;
    top: 85px;
  }

  :global(.thin-header) & {
    top: 50px;
  }

  & > li {
    width: 33%;
    padding: 10px 0;
    list-style: none;

    & > span {
      font-size: 12px;
      display: block;
      width: auto;
      margin: 0 5px 0 5px;
      padding: 3px;
      text-align: center;
      text-transform: uppercase;
      color: #010101;
      border-radius: 5px;
      background-color: #fff;
      cursor: pointer;

      :global(.dark) & {
        @apply bg-gray-500 text-white;
      }

      .mobileStream & {
        padding: 0.5em 0;
      }
    }
  }

  & .active > span {
    color: #fff;
    background-color: #804b77;
    :global(.dark) & {
      @apply bg-gkPurple;
    }
  }

}

