@import '$Styles/config.less';

.searchbox {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: 5px;
    background-color: darken(@main-green,10%);

    & input {
        border:0;
        outline:none;
        &::-webkit-input-placeholder{
            color: @main-green;
        }
        &::-moz-placeholder{
            color:@main-green;
        }
        &:-ms-input-placeholder{
            color:@main-green
        }
        &:-moz-placeholder{
            color:@main-green;
        }
    }
    &__text {
        border: 0;
        //padding:7px 0 7px 25px;
        width:100%;
        color:white;
        background-color: transparent;
    }
    &__submit {
        width: 24px;
        height: 24px;
        margin: 0 5px;
        //background: url("@{image-path}/Search.svg") no-repeat center center;
        background-size: 18px;
        //position:absolute;
        //left:0;
        //top:0.2em;
        //padding:0px;
    }
}
.searchBox {
    & .list {}
    & .title {
        padding: 5px;
        background-color: darken(@main-green,10%);
        color: white;
        &:hover {
            background-color: darken(@main-green,10%);
        }

    }
    & .container {
        ul > .listItem { cursor: pointer; border-bottom: 1px solid lightgray; }
        ul > .listItem:hover {
            color: @main-purple;
        }
    }
    & form {
        display: flex;
        input[type=text]::placeholder{ color:white; }
        input[type=text]:focus::placeholder { color: transparent; }
        input{ outline: none; }
    }
    & .submit { padding: 0 0.5em; }
}

.searchbox{
    &__list {
        display: none;
        margin: 0;
        padding: 0;
        border: 1px solid #cecece;
        border-top: none;
        position:absolute;
    }
    &__list-item{
        font-family: "Ubuntu Condensed", serif;
        font-size: 14px;
        display: block;
        padding: 4px 15px 4px 15px;
        list-style: none;
        cursor: pointer;
        border-top: 1px dotted #e8e8e8;
        color: #222222;
        background-color: #e8e8e8;
    }
}
