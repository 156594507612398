@import '$Styles/config.less';

.dropdownBlock {
  position: relative;
  display: inline-block;
  font-family: Roboto, sans-serif;
  font-size: 1rem;

  & .dropdownButton {
    color: white;
    padding: 0.25em;
    font-size: 0.8em;
    border: none;
    cursor: pointer;
    width: max-content;
  }
  
  &:hover .dropdownButton{
    background-color: lighten(@main-green,10%);
  }

  & .dropdownContent{
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    z-index: 1;
    font-size: 0.8em;

    & ul > li {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      font-family: "Ubuntu Condensed", sans-serif;
    }
    & ul > li:hover {
      background-color: #f1f1f1;
    }
  }

  &.active:hover .dropdownContent {
    display:block;
    max-height: 20rem;
    overflow-y: scroll;
  }
}
